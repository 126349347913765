<template>
    <BannerTemplate :bannerSource="bannerSource">
        <LoadingIcon :isLoading="isLoading" />
        <div  class="px-2 mt-n5" v-if="!isLoading">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex">
                        <span class="display-1">{{roadtrip.tripName}}</span>
                        <span class="body-1 tag-wrap">
                            <v-alert class="tag mx-3 mt-3 mb-0 pa-0 text-center red" 
                                :class="`${roadtrip.labelColor}`"
                                text--white
                                width="100"
                                dense
                                prominent
                                text>{{roadtrip.label}}</v-alert>
                        </span>
                    </v-col>
                    <v-col cols="12" class="">{{formatDayMonth(roadtrip.date)}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="isProcessed">
                        <p>
                            Bedankt voor uw bestelling. Uw aanmelding voor de {{roadtrip.tripName}} met ordernummer {{orderNumber}} is ontvangen en verwerkt. U ontvangt van ons ter bevestiging een e-mail.
                        </p>
                        <p>
                            Vergeet ons niet te volgen op onze socials zodat u altijd op de hoogte bent van de nieuwste updates. Misschien ziet u uw voertuig ook wel voorbij komen!
                        </p>
                    </v-col>
                    <v-col cols="12" v-if="!isProcessed && orderNumber == ''">
                        <div>
                            Er is iets misgegaan bij het verwerken van uw bestelling, maar het kan zijn dat we uw betaling wel hebben ontvangen. Neem voor de zekerheid contact met ons op via
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a
                                    style="color:#76baff"
                                    target="_blank"
                                    href="mailto:service@roadpursuit.com"
                                    @click.stop
                                    v-on="on">
                                    service@roadpursuit.com
                                </a>
                                </template>
                                Opent uw mail app
                            </v-tooltip>
                            .
                        </div>
                    </v-col>
                    <v-col cols="12" v-if="!isProcessed && orderNumber != ''">
                        <div>
                            Er is iets misgegaan bij het verwerken van uw bestelling met ordernummer {{orderNumber}}, maar het kan zijn dat we uw betaling wel hebben ontvangen. Neem voor de zekerheid contact met ons op via
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <a
                                    style="color:#76baff"
                                    target="_blank"
                                    href="mailto:service@roadpursuit.com"
                                    @click.stop
                                    v-on="on">
                                    service@roadpursuit.com
                                </a>
                                </template>
                                Opent uw mail app
                            </v-tooltip>
                            .
                        </div>
                     </v-col>
                    <v-col cols="12" >
                        <v-btn color="primary" block @click="goToHome()">
                            Naar de homepage
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import LoadingIcon from '@/components/common/LoadingIcon';
import FormatMixin from "@/mixins/format.mixin";
import RoadtripMixin from "@/mixins/roadtrip.mixin";
import MenuHelperMixin from "@/mixins/menu-helper.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'RoadtripAanmelding',
    components: 
    {
        BannerTemplate,
        LoadingIcon
    },
    mixins: [MenuHelperMixin, FormatMixin, RoadtripMixin, RouteMixin, MetaTagsMixin],
    beforeCreate(){
        this.$store.dispatch('menuHelper/getRoadtripLabels');
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        const orderId = this.$route.params.orderId;
        this.$store.dispatch('storeModule/checkPaymentStatusByOrderId', orderId)
            .then(data => {
                this.roadtrip    = this.mapRoadtripData(data.productViewModel);
                this.isProcessed = data.success;
                this.orderNumber = data.orderNumber;
                this.isLoading   = false;
            })
            .catch(() => {
                this.isProcessed = false;
                this.isLoading = false;
            })
    },
    metaInfo() {
        return {
        title: this.roadtrip.tripName ? `| Aanmelding | ${this.roadtrip.tripName}` : `| Aanmelding`,
        meta: [
            this.getKeywords(`${this.roadtrip.label}, ${this.roadtrip.tripName}`),
            this.getMetaDescription(`${this.roadtrip.label}`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`${this.roadtrip.tripName}`, `${this.roadtrip.label}`, `${window.location.origin}${this.bannerSource}`, this.roadtrip.label),
        ]
        };
    },
    data: () => ({
        bannerSource:  require('@/assets/album/Eifel Tour/eifel-tour-01.jpg'),
        firstname: '',
        isProcessed: false,
        orderNumber: '',
        isLoading: true,
        roadtrip: {}
    }),
}
</script>
